<template>
  <v-app>
    <v-main>
      <v-container fluid fill-height class="px-0 py-0">
        <v-layout row wrap fill-height>
          <v-flex sm12 class="hidden-sm-and-down primary">
            <v-layout row fill-height align-center justify-center>
              <v-flex xs11>
                <v-layout column>
                  <v-flex xs12>
                    <span class="text-center brand white--text">idenfit</span>
                  </v-flex>
                  <v-flex xs12>
                    <router-view></router-view>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  /**
   * @project personal-idenfit
   * @developer Halil Kılıçarslan
   * @description auth-layout Component Logic
   * @date 24.07.2020
   */
  export default {
    name: "page-layout"
  };
</script>

<style scoped>
  .brand {
    font-family: "Paytone One", sans-serif;
    font-size: 100px;
    display: block;
  }
</style>
